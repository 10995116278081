import api from '@/configs/GlobalRequestHandler.js';

export default {
  getAwards:(campaignId) => {
    return api.getRequest(`/campaigns_awards/${campaignId}`)
  },
  getParticipant:(campaignId, quota) => {
    return api.getRequest(`/participants/${campaignId}/find_quota/${quota}`)
  },
  updateAwards:(campaignId, awards) => {
    return api.putRequestWithBody(`/campaigns_awards/${campaignId}`, awards)
  },
  setCampaignWinners:(campaignId, awards) => {
    return api.putRequestWithBody(`/campaigns_awards/winners/${campaignId}`, awards)
  },
}