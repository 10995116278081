<template>
  <dialog :open="isOpen" class="default-dialog">
    <div class="dialog-content">
      <header class="dialog-header">
        <div>
          <h2 class="dialog-title">{{ title }}</h2>
        </div>
        <button @click="closeModal" class="close-icon-button">✖</button>
      </header>

      <main class="dialog-body">
        <table>
          <thead>
            <th>Arrecadação estimada</th>
            <th>Taxa de publicação</th>
          </thead>

          <tbody>
            <tr>
              <td>Entre R$ 0,00 e R$ 100,00</td>
              <td>R$ 7,00</td>
            </tr>
            <tr>
              <td>Entre R$ 100,01 e R$ 200,00</td>
              <td>R$ 17,00</td>
            </tr>
            <tr>
              <td>Entre R$ 200,01 e R$ 400,00</td>
              <td>R$ 27,00</td>
            </tr>
            <tr>
              <td>Entre R$ 400,01 e R$ 700,00</td>
              <td>R$ 37,00</td>
            </tr>
            <tr>
              <td>Entre R$ 700,01 e R$ 1.000,00</td>
              <td>R$ 47,00</td>
            </tr>
            <tr>
              <td>Entre R$ 1.000,01 e R$ 2.000,00</td>
              <td>R$ 67,00</td>
            </tr>
            <tr>
              <td>Entre R$ 2.000,01 e R$ 4.000,00</td>
              <td>R$ 77,00</td>
            </tr>
            <tr>
              <td>Entre R$ 4.000,01 e R$ 7.100,00</td>
              <td>R$ 127,00</td>
            </tr>
            <tr>
              <td>Entre R$ 7.100,01 e R$ 10.000,00</td>
              <td>R$ 197,00</td>
            </tr>
            <tr>
              <td>Entre R$ 10.000,01 e R$ 20.000,00</td>
              <td>R$ 247,00</td>
            </tr>
            <tr>
              <td>Entre R$ 20.000,01 e R$ 30.000,00</td>
              <td>R$ 497,00</td>
            </tr>
            <tr>
              <td>Entre R$ 30.000,01 e R$ 50.000,00</td>
              <td>R$ 997,00</td>
            </tr>
            <tr>
              <td>Entre R$ 50.000,01 e R$ 70.000,00</td>
              <td>R$ 1.497,00</td>
            </tr>
            <tr>
              <td>Entre R$ 70.000,01 e R$ 100.000,00</td>
              <td>R$ 1.997,00</td>
            </tr>
            <tr>
              <td>Entre R$ 100.000,01 e R$ 150.000,00</td>
              <td>R$ 2.997,00</td>
            </tr>
            <tr>
              <td>Acima de R$ 150.000,00</td>
              <td>R$ 3.997,00</td>
            </tr>
          </tbody>
        </table>
      </main>
    </div>
  </dialog>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      title: 'Taxas de publicação',
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.default-dialog {
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background: none;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
}

dialog {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

dialog[open] {
  opacity: 1;
  pointer-events: inherit;
}

dialog::backdrop {
  backdrop-filter: blur(2px);
  animation: fadein 0.2s linear;
}

.dialog-content {
  width: 740px;
  max-width: 100%;
  background: white;
  border-radius: 8px;
  padding: 20px;
  z-index: 2;
  transform: scale(0.96);
  transition: transform 0.2s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

dialog[open] .dialog-content {
  transform: scale(1);
}

.dialog-title {
  font-family: sans-serif;
  font-size: 26px;
  color: #111111;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  justify-self: center;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(144, 149, 160, 0.5);
  color: #9095a0;
  z-index: 9999999;
  backdrop-filter: blur(4px);
  width: 100vw;
  height: 100vh;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dialog-body {
  margin: 20px 0 0 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.button-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #0f172a;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  margin-bottom: 0;

  &:hover {
    background-color: #d6d6d6;
    color: #0f172a;
  }
}

.close-icon-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.submit-button {
  background-color: #661cb0;
  color: white;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  position: relative;
  transition: background-color 0.2s ease;
  cursor: pointer;
  margin-bottom: 0;

  &:hover {
    background-color: #7b23d3;
    color: white;
  }
}

.quote-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quote-form input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.awarded-quotes-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.awarded-quotes-item {
  border: 1px solid #d2d6da !important;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #36454F;
}

.quote-number {
  display: flex;
  gap: 5px;
  align-items: center;
}

.no-quotes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 40px 0 40px 0;
  color: #3c3d42;
}

.remove-quote-btn {
  border: none;
  background-color: transparent;
  padding: 0; margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

table {
  width: 100%;
  border: 2px solid #33ff77;
  margin: 20px 0;
  color: #33ff77;
  background-color: #0d2031;
  overflow: hidden;
  border-collapse: separate;
  -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
      border-radius: 15px;
}

tbody {
  border-radius: 10px;
}

thead th {
  padding: 4px;
  text-align: left;
  color: #33ff77;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid #33ff77;
}

tbody td {
  padding: 2px;
  font-size: 13px;
  text-align: left;
  color: #ffffff;
  text-align: center;
}

tbody tr:last-child td {
  border-bottom: none;
}

tbody tr:nth-child(odd) {
  background-color: #0f172a;
}

tbody tr:nth-child(even) {
  background-color: #112233;
}

table th:first-child, table td:first-child {
  border-left: none;
}

</style>

