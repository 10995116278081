<template>
  <dialog :open="isOpen" class="default-dialog">
    <div class="dialog-content">
      <header class="dialog-header">
        <div>
          <h2 class="dialog-title">{{ title }}</h2>
        </div>
        <button @click="closeModal" class="close-icon-button">✖</button>
      </header>

      <ul class="awarded-quotes-list" v-if="promotions.length > 0 && this.isAddingPromotion == false">
        <li v-for="(promotion, index) in promotions" :key="index">
          <div class="awarded-quotes-item">
            <div style="display: flex; flex-direction: row;">
              <span class="promotion-number">
                <i class="material-icons">paid</i>
                <strong>Quantidade:</strong>
                {{ promotion.qnt }} cotas
              </span>

              <span style="margin-inline: 14px;">|</span>
              
              <span>
                <strong>Valor:</strong> {{ formatPromotionValue(promotion.value) }}
              </span>
            </div>

            <button class="remove-promotion-btn">
              <i class="material-icons" @click="removePromotion(promotion, index)">delete</i>
            </button>
          </div>
        </li>
      </ul>

      <div v-else-if="promotions.length == 0 && this.isAddingPromotion == false" class="no-quotes">
        <i class="material-icons">sentiment_dissatisfied</i>
        <span>
          Nenhuma promoção foi adicionada...
        </span>
      </div>

      <main class="dialog-body">
        <div v-if="!isAddingPromotion">
          <button
            class="btn mb-0 submit-button"
            @click.prevent="showPromotionForm"
          >
            <span>Adicionar promoção</span>
            <i class="material-icons ticket-icon">add</i>
          </button>
        </div>
        <div v-else class="promotion-form">
          <div class="input-group input-group-outline m-0 d-flex flex-column">
            <label class="input-label">
              <span class="text-xs text-uppercase text-body font-weight-bolder"
                >Quantidade de cotas</span
              >
              <input
                v-model.number="newPromotion.qnt"
                type="text"
                class="form-control default-bordered-input maska"
                data-maska-number-locale="br"
                data-maska-number
              />
            </label>
          </div>
          <div class="input-group input-group-outline m-0 d-flex flex-column">
            <label class="input-label">
              <span class="text-xs text-uppercase text-body font-weight-bolder"
                >Valor</span
              >
              <input
                v-model.lazy="newPromotion.value"
                v-money3="config"
                type="text"
                class="form-control default-bordered-input"
              />
            </label>
          </div>
          
          <div class="button-field">
            <button @click="savePromotion" class="btn submit-button" :disabled="!isFieldsFilled || this.isSubmitting">Salvar</button>
            <button @click="cancelPromotionCreation" class="btn cancel-button">Cancelar</button>
          </div>
        </div>
      </main>
    </div>

    <RemoveDialog
      :is-open="isDeleteDialogOpen"
      @close="this.isDeleteDialogOpen = false"
      :title="'Remover promoção'"
      :removeText="'Esta ação é irreversível e ocorrerá no momento em que confirmar a remoção. Tem certeza que deseja continuar com a deleção desta promoção da campanha?'"
      @confirm="handleConfirmDelete"
    />
  </dialog>
</template>

<script>
import { vMaska } from "maska/vue";
import { addMoneyMask } from '@/helpers/moneyMask.js'
import { RouteName } from "/src/store/routeName";
import PromotionsHttp from "/src/http/PromotionsHttp.js"
import RemoveDialog from "/src/views/components/RemoveDialog.vue";

export default {
  directives: {
    maska: vMaska,
  },
  props: {
    isOpen: Boolean,
    receivedData: Array,
    campaignId: Number || null,
  },
  components: {
    RemoveDialog,
  },
  data() {
    return {
      title: 'Promoções da campanha',
      promotions: [],
      isAddingPromotion: false,
      newPromotion: {
        qnt: null,
        value: ''
      },
      itemToRemove: null,
      indexToRemove: null,
      isDeleteDialogOpen: false,
      isSubmitting: false
    };
  },
  watch: {
    receivedData: {
      handler(data) {
        if(data && Object.keys(data).length > 0) {
          this.promotions = data;
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.resetForm();
    },
    showPromotionForm() {
      this.isAddingPromotion = true;
    },
    async savePromotion() {
      if (this.newPromotion.qnt && this.newPromotion.value) {
        if(this.isEditForm) {
          try {
            this.isSubmitting = true;
            await PromotionsHttp.createPromotion([{...this.newPromotion, value: this.parseQuotaPrice(this.newPromotion.value)}], this.campaignId)
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: 'Ocorreu um erro.', detail: 'Não foi possível inserir o prêmio. Tente novamente.', life: 3000 });
            this.closeModal();
            return;
          }
        }

        this.promotions.push({ qnt: this.newPromotion.qnt, value: this.parseQuotaPrice(this.newPromotion.value) });
        this.$emit('update:promotions', this.promotions);
        this.resetForm();
      }
    },
    removePromotion(item, index) {
      if (this.isEditForm) {
        this.itemToRemove = item;
        this.indexToRemove = index;
        this.isDeleteDialogOpen = true;
      } else {
        this.promotions.splice(index, 1);
        this.$emit('update:promotions', this.promotions);
      }
    },
    async handleConfirmDelete() {
      try {
        await PromotionsHttp.deletePromotion(this.itemToRemove.id);
        this.promotions.splice(this.indexToRemove, 1);
        this.$emit('update:promotions', this.promotions);
      } catch (error) {
        this.closeModal();
        this.$toast.add({ severity: 'error', summary: 'Ocorreu um erro.', detail: 'Não foi possível remover a cota premiada.', life: 3000 });
      }
    },
    cancelPromotionCreation() {
      this.resetForm();
    },
    resetForm() {
      this.newPromotion.qnt = null;
      this.newPromotion.value = null;
      this.isAddingPromotion = false;
    },
    parseQuotaPrice(price) {
      let numericValue = price.replace(/[^\d,]+/g, '');

      numericValue = numericValue.replace(',', '.');

      return parseFloat(numericValue);
    },
  },
  computed: {
    isFieldsFilled() {
      return this.newPromotion.qnt > 0 && this.parseQuotaPrice(this.newPromotion.value) > 0;
    },
    config() {
      return {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        disableNegative: true,
        masked: false,
      };
    },
    formatPromotionValue() {
      return (value) => (value ? addMoneyMask(value) : "Valor indisponível")
    },
    isEditForm() {
      return this.$route.name == RouteName.EditRaffle;
    }
  }
};
</script>

<style scoped lang="scss">
.default-dialog {
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background: none;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
}

dialog {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

dialog[open] {
  opacity: 1;
  pointer-events: inherit;
}

dialog::backdrop {
  backdrop-filter: blur(2px);
  animation: fadein 0.2s linear;
}

.dialog-content {
  width: 600px;
  max-width: 100%;
  background: white;
  border-radius: 8px;
  padding: 20px;
  z-index: 2;
  transform: scale(0.96);
  transition: transform 0.2s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

dialog[open] .dialog-content {
  transform: scale(1);
}

.dialog-title {
  font-family: sans-serif;
  font-size: 26px;
  color: #111111;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  justify-self: center;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(144, 149, 160, 0.5);
  color: #9095a0;
  z-index: 9999999;
  backdrop-filter: blur(4px);
  width: 100vw;
  height: 100vh;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dialog-body {
  margin: 20px 0 0 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.button-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #0f172a;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  margin-bottom: 0;

  &:hover {
    background-color: #d6d6d6;
    color: #0f172a;
  }
}

.close-icon-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.submit-button {
  background-color: #661cb0;
  color: white;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  position: relative;
  transition: background-color 0.2s ease;
  cursor: pointer;
  margin-bottom: 0;

  &:hover {
    background-color: #7b23d3;
    color: white;
  }
}

.promotion-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.promotion-form input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.awarded-quotes-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.awarded-quotes-item {
  border: 1px solid #d2d6da !important;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #36454F;
}

.promotion-number {
  display: flex;
  gap: 5px;
  align-items: center;
}

.no-quotes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 40px 0 40px 0;
  color: #3c3d42;
}

.remove-promotion-btn {
  border: none;
  background-color: transparent;
  padding: 0; margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

