<template>
  <div class="payment-container">
    <div v-if="loading" class="loading-spinner">
      <div class="spinner"></div>
    </div>
    <div v-else>
      <div v-if="raffle.status === 'ACTIVE'" class="raffle-ready">
        <h1>🎉 Pagamento identificado! 🎉</h1>
        <p>Sua rifa já está ativa! Boa sorte em sua campanha!</p>
        <button @click="goToDashboard" class="primary-button">Voltar para início</button>
      </div>
      <div v-else>
        <div class="success-message">
          <h1>Sua campanha foi criada com sucesso!</h1>
          <p>Realize o pagamento utilizando as opções abaixo:</p>
        </div>
        <div class="payment-instructions">
          <div class="instruction-box">
            <h2>Pix Copia e Cola</h2>
            <p class="pix-code">{{ raffle.qr_code }}</p>
            <button @click="copyPixCode" class="copy-button">Copiar código</button>
          </div>
          <div class="qr-section">
            <h2>Ou escaneie o QR Code</h2>
            <div class="qr-code">
              <QrcodeVue
                style="width: 200px; height: 200px;"
                :value="raffle.qr_code"
                :level="level"
                :render-as="renderAs"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import RafflesHttp from "../../../http/RafflesHttp";

export default {
  name: "PaymentPage",
  components: { QrcodeVue },
  data() {
    return {
      level: 'M',
      renderAs: 'svg',
      raffle: [],
      loading: true,
    };
  },
  methods: {
    copyPixCode() {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(this.raffle.qr_code)
        .then(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Sucesso!',
            detail: 'O código Pix foi copiado para a área de transferência.',
            life: 3000,
          });
        })
        .catch(() => {
          this.manualCopyFallback();
        });
    } else {
      this.manualCopyFallback();
    }
  },
  manualCopyFallback() {
    const textArea = document.createElement('textarea');
    textArea.value = this.raffle.qr_code;
    textArea.style.position = 'fixed';
    textArea.style.opacity = '0';
    document.body.appendChild(textArea);
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.$toast.add({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'O código Pix foi copiado para a área de transferência.',
          life: 3000,
        });
      } else {
        throw new Error('Falha ao copiar');
      }
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'Erro!',
        detail: 'Não foi possível copiar o código Pix. Por favor, copie manualmente.',
        life: 3000,
      });
    } finally {
      document.body.removeChild(textArea);
    }
  },
    async getRaffle() {
      const raffle = await RafflesHttp.getRaffle(this.$route.params.slug);
      this.raffle = raffle.data;
    },
    startPolling() {
      this.pollingInterval = setInterval(async () => {
        try {
          const response = await RafflesHttp.getRaffle(this.$route.params.slug);
          this.raffle = response.data;
          if (this.raffle.status === 'ACTIVE') {
            clearInterval(this.pollingInterval);
          }
        } catch (error) {
          console.error('Erro ao verificar o status da campanha:', error);
        }
      }, 2500);
    },
    stopPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
      }
    },
    goToDashboard() {
      this.$router.push('/');
    },
  },
  mounted() {
    this.getRaffle().finally(() => {
      this.loading = false;
      this.startPolling();
    });
  },
  beforeUnmount() {
    this.stopPolling();
  },
};
</script>


<style scoped lang="scss">
.payment-container {
  margin: auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.8s ease-in-out;
}

.success-message h1 {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 15px;
}

.success-message p {
  font-size: 18px;
  color: #555555;
}

.payment-instructions {
  margin-top: 40px;
}

.instruction-box {
  margin-bottom: 30px;
  padding: 20px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 12px;
}

.instruction-box h2 {
  font-size: 20px;
  color: #6a1b9a;
  margin-bottom: 10px;
}

.pix-code {
  font-size: 16px;
  font-family: monospace;
  background: #eef2f7;
  padding: 12px;
  border-radius: 6px;
  word-break: break-word;
  margin-bottom: 12px;
}

.copy-button {
  padding: 10px 18px;
  font-size: 14px;
  color: #fff;
  background-color: #6a1b9a;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #501570;
  transform: scale(1.05);
}

.qr-section h2 {
  font-size: 20px;
  color: #6a1b9a;
  margin-bottom: 20px;
}

.qr-code {
  display: inline-block;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.raffle-ready {
  text-align: center;
  padding: 40px;
  background: #e8f5e9;
  border-radius: 16px;
  animation: bounceIn 1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

.raffle-ready h1 {
  font-size: 32px;
  font-weight: 700;
  color: #43a047;
}

.raffle-ready p {
  font-size: 18px;
  color: #555555;
  margin: 10px 0 30px;
}

.primary-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #43a047;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease-in-out;
}

.primary-button:hover {
  background-color: #2e7d32;
  transform: translateY(-2px);
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

.spinner {
  margin-top: 10px;
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-top: 5px solid #6a1b9a;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.9);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.02);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

</style>
