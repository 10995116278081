<template>
  <div class="container-fluid m-0 p-0">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">Histórico</h6>
            </div>
          </div>

          <div class="card-body p-4">
            <DefaultSelect
              label="Selecione uma campanha"
              :options="raffles"
              itemText="name"
              itemValue="id"
              @change="handleCampaign"
              placeholder="Selecione..."
            />

            <DefaultSelect
              v-if="campaignSelected"
              label="Status da reserva"
              :options="
                [
                  { name: 'Sem filtro', value: 'NO_FILTER' },
                  { name: 'Pendente', value: 'WAITING' },
                  { name: 'Pago', value: 'APPROVED' },
                  { name: 'Expirado', value: 'EXPIRED' },
                ]
              "
              itemText="name"
              itemValue="value"
              @change="handleFilter"
              placeholder="Selecione..."
              v-model="status"
            />

            <DefaultSelect
              v-if="campaignSelected"
              label="Filtro da Data"
              :options="
                [
                  { name: 'Sem filtro', value: 'false' },
                  { name: 'Hoje', value: 'true' },
                ]
              "
              itemText="name"
              itemValue="value"
              @change="handleFilter"
              placeholder="Selecione..."
              v-model="today"
            />

            <DefaultSelect
              v-if="campaignSelected"
              label="Tipo do filtro textual"
              :options="
                [
                  { name: 'Sem filtro', value: 'NO_FILTER' },
                  { name: 'Nome do comprador', value: 'name' },
                  { name: 'Telefone', value: 'phone' },
                  { name: 'Cota', value: 'quota' },
                ]
              "
              itemText="name"
              itemValue="value"
              placeholder="Selecione..."
              v-model="filter"
            />

            <div
              style="display: flex; align-items: end; gap: 15px;"
              v-if="campaignSelected && filter != 'NO_FILTER'"
            >
              <DefaultInput
                class="mb-0"
                v-model="search"
                label="Pesquisa"
                placeholder="Insira o texto da pesquisa"
              />

              <DefaultButton
                :label="'Buscar'"
                :buttonColor="'#661cb0'"
                :textColor="'#ffffff'"
                :spinnerBgColor="'#ffffff'"
                :spinnerColor="'#37016e'"
                style="margin-bottom: 0.5rem; width: 100px;"
                @confirm="getOrders(campaignSelected)"
              />
            </div>

            <template v-if="campaignSelected">
              <div class="card-body px-0 pt-4">
                <div
                  class="order-card mb-3 p-3 shadow-sm rounded"
                  v-for="(o, index) in orders"
                  :key="o.id"
                >
                  <div class="order-header">
                    <div class="order-header-title">
                      <h5 class="order-name mb-0">{{ o.name }}</h5>
                      <span
                        class="badge"
                        :class="{
                          'badge-green': o.payment_status === 'APPROVED',
                          'badge-orange': o.payment_status === 'WAITING',
                          'badge-red': o.payment_status === 'EXPIRED',
                          'badge-gray': o.payment_status === 'CANCELED'
                        }"
                      >
                        {{ getPaymentStatusText(o.payment_status) }}
                      </span>
                    </div>

                    <button class="more-btn" @click="toggle(index, $event)">
                      <i class="material-icons">more_vert</i>
                    </button>
                    <PrimeMenu
                      ref="menu"
                      :id="`overlay_menu_${index}`"
                      :model="
                        [
                          ...items,
                          { label: 'Marcar como pago',
                            command: () => {
                              markAsPaid(o)
                            }
                          },
                          { label: 'Entrar em contato',
                            command: () => {
                              sendWhatsAppMessage(o)
                            }
                          },
                          { label: 'Cancelar reserva',
                            command: () => {
                              cancelOrder(o)
                            }
                          },
                        ]
                      "
                      :popup="true"
                    />
                  </div>

                  <div class="order-body mt-3">
                    <div class="order-info">
                      <span>
                        <strong>Telefone: </strong>
                        {{ o.phone.toString().replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "+55 ($1) $2$3-$4") }}
                      </span>
                      <span v-if="o.email && o.email.trim() !== ''">
                        <strong>E-mail: </strong>
                        {{ o.email }}
                      </span>
                      <span>
                        <strong>Valor: </strong>
                        {{ o.value.toLocaleString("pt-br", { style: "currency", currency: "BRL" }) }}
                      </span>
                      <span>
                        <strong>Taxa: </strong>
                        {{ o.fee.toLocaleString("pt-br", { style: "currency", currency: "BRL" }) }}
                      </span>
                      <span>
                        <strong>Criado em: </strong>
                        {{ new Date(o.created_at).toLocaleDateString() }}, {{ new Date(o.created_at).toLocaleTimeString() }}
                      </span>
                    </div>
                    <div class="order-quotas mt-2">
                      <span class="quota-badge badge-green mr-1" v-for="(q, index) in JSON.parse(o.quotas)" :key="index">
                        {{ this.formatQuota(q) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="no-data-message">
                <p>Selecione uma campanha para accesar o histórico.</p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import axios from "axios";
import config from "@/store/config";
import Loading from "@/components/Loading.vue";
import DefaultSelect from "@/components/DefaultSelect.vue";
import DefaultInput from "@/components/DefaultInput.vue";
import DefaultButton from "@/components/DefaultButton.vue";
import PrimeMenu from 'primevue/menu'
import RafflesHttp from '/src/http/RafflesHttp.js'

const api = axios.create({
  baseURL: config.apiUrl,
});

export default {
  name: "orders",
  components: {
    Loading,
    DefaultSelect,
    DefaultInput,
    DefaultButton,
    PrimeMenu
  },
  data() {
    return {
      campaignSelected: "",
      raffles: [],
      items: [],
      orders: [],
      search: "",
      status: "NO_FILTER",
      filter: "NO_FILTER",
      today: "false",
      pages: 0,
      loading: true,
      raffleObject: {},
    };
  },
  async mounted() {
    await this.init();
  },
  watch: {
    "$store.state.isDarkMode": function () {
      if (this.$store.state.isDarkMode) {
        document.querySelectorAll(".input-default").forEach((el) => {
          el.classList.add("dark-mode-input");
        });
      } else {
        document.querySelectorAll(".input-default").forEach((el) => {
          el.classList.remove("dark-mode-input");
        });
      }
    },
  },
  methods: {
    async init() {
      this.loading = true;
      await this.verifyDarkMode();
      await this.getRaffles();
      this.loading = false;
    },
    async verifyDarkMode() {
      if (this.$store.state.isDarkMode) {
        document.querySelectorAll(".input-default").forEach((el) => {
          el.classList.add("dark-mode-input");
        });
      } else {
        document.querySelectorAll(".input-default").forEach((el) => {
          el.classList.remove("dark-mode-input");
        });
      }
    },
    handleOptions(id) {
      let actual = document.querySelector(`.options-list-${id}`).style.display
        ? document.querySelector(`.options-list-${id}`).style.display
        : "none";

      document.querySelectorAll(".options-list").forEach((el) => {
        el.style.display = "none";
      });

      if (actual == "none") {
        document.querySelector(`.options-list-${id}`).style.display = "block";
      } else {
        document.querySelector(`.options-list-${id}`).style.display = "none";
      }
    },
    async getRaffles() {
      try {
        const response = await api.get(
          "/campaigns/minimum",
          await this.$store.state.config
        );
        this.raffles = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getOrders(idCampaign) {
      try {
        const parsebool = (str) => {
          if (str == "true") {
            return true;
          } else {
            return false;
          }
        };
        let body = {
          today: parsebool(this.today),
          search: this.search,
          status: this.status,
          filter: this.filter,
        };

        if(body.filter == 'NO_FILTER') {
          body.filter = '';
        }

        if(body.status == 'NO_FILTER') {
          body.status = '';
        }

        const response = await api.post(
          `/participants/orders/${idCampaign}/1`,
          body,
          await this.$store.state.config
        );
        this.orders = response.data.data;
        this.pages = response.data.pages;
      } catch (error) {
        console.error(error);
      }
    },
    async handleCampaign(e) {
      this.ranking = [];
      this.pages = 0;
      this.loading = true;

      this.today = "false";
      this.status = "NO_FILTER";
      this.filter = "NO_FILTER";
      this.search = "";

      await this.getOrders(e.target.value);
      
      try {
        const slug = this.raffles.find((item) => item.id == e.target.value);
        if(slug) {
          const response = await RafflesHttp.getRaffle(slug.slug);
          this.raffleObject = response.data;
        }
      } catch(error) {
        console.error(error);
      }

      this.campaignSelected = e.target.value;
      this.loading = false;
    },
    async handleFilter(e) {
      this.filter = e.target.value;
      this.loading = true;
      await this.getOrders(this.campaignSelected);
      this.loading = false;
    },
    async markAsPaid(d) {
      this.loading = true;
      try {
        await api.put(
          `/participants/${d.id}/mark_paid`,
          {},
          await this.$store.state.config
        );
        await this.getOrders(this.campaignSelected);
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    toggle(index, event) {
      this.$refs.menu[index].toggle(event);
    },
    // getWhatsAppUrl(telefone, mensagem) {
    //   const baseURL = "https://wa.me/";
    //   const mensagemCodificada = encodeURIComponent(mensagem);
    //   return `${baseURL}${telefone}?text=${mensagemCodificada}`;
    // },
    getWhatsAppUrl(telefone) {
      const baseURL = "https://wa.me/";
      return `${baseURL}${telefone}`;
    },
    sendWhatsAppMessage(o) {
      const phone = o.phone;
      const cleanedPhone = phone.replace(/[\s()-]/g, '');
      const link = this.getWhatsAppUrl(cleanedPhone);
      window.open(link, '_blank').focus();
    },
    async cancelOrder(o) {
      try {
        await api.delete(
          `/participants/${o.id_campaing}/${o.id}`,
          await this.$store.state.config
        );

        this.$toast.add({ severity: 'success', summary: 'Sucesso!', detail: 'Pedido cancelado.', life: 3000 });
 
        await this.getOrders(this.campaignSelected);
      } catch (error) {
        console.error(error);
        this.$toast.add({ severity: 'error', summary: 'Houve um erro!', detail: 'Verifique as informações do pedido e tente novament.', life: 3000 });
      }
    },
    getPaymentStatusText(status) {
      switch (status) {
        case 'APPROVED':
          return 'Pago';
        case 'WAITING':
          return 'Pendente';
        case 'CANCELED':
          return 'Cancelado';
        default:
          return 'Expirado';
      }
    },
    formatQuota(quota) {
      const totalDigits = String(this.raffleObject.qnt_quotas - 1).length;
      return String(quota).padStart(totalDigits, '0');
    }
  }
};
</script>

<style scoped lang="scss">
.input-default {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.dark-mode-input {
  background-color: #1a2035 !important;
  border: 1px solid #10131f !important;
  color: #fff;
}
.orders .order-name {
  color: #16a34a;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.96rem;
  letter-spacing: 0.05em;
}
.orders .order-infos span {
  font-size: 0.85rem;
}
.badge-green {
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
  padding: 0.1rem 0.3rem;
  text-transform: capitalize;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-color: rgb(22 163 74 / 0.2);
  border: 1pt solid #16a34a;
}

.badge-orange {
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(253 236 234 / var(--tw-bg-opacity));
  padding: 0.1rem 0.3rem;
  text-transform: capitalize;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(231 166 41 / var(--tw-text-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-color: rgba(226, 144, 50, 0.2);
  border: 1pt solid #e9a72e;
}

.badge-red {
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(253 236 234 / var(--tw-bg-opacity));
  padding: 0.1rem 0.3rem;
  text-transform: capitalize;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-color: rgb(185 28 28 / 0.2);
  border: 1pt solid #b91c1c;
}

.badge-gray {
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(230 230 230 / var(--tw-bg-opacity));
  padding: 0.1rem 0.3rem;
  text-transform: capitalize;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(73 73 73 / var(--tw-text-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-color: rgb(185 28 28 / 0.2);
  border: 1pt solid #494949; 
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-3 {
  width: 0.75rem;
  height: 0.75rem;
}

.options-button {
  float: right;
  border: 1px solid #7b809a;
  display: block;
  border-radius: 4px;
}

.options-list ul {
  list-style: none;
  padding: 0;
}

.options-list {
  display: none;
  position: absolute;
  right: 1.5rem;
  margin-top: 2rem;
  background-color: #fff;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  z-index: 1;
}

.options-list ul li {
  padding: 5px;
  cursor: pointer;
  font-size: 0.85rem;
  font-weight: 300;
  text-transform: capitalize;
  color: #585b68;
}

.mr-1 {
  margin-right: 1px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-3 {
  margin-right: 3px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-9 {
  margin-right: 9px;
}

.mr-10 {
  margin-right: 10px;
}

.order-card {
  border-radius: 16px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #585b682a;
}
.order-header {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &-title {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
.order-info span {
  display: block;
  margin: 4px 0;
  font-size: 0.9rem;
}
.quota-badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  border: 1px solid #18813f;
  background: #f0fdf4;
  color: #18813f;
  font-size: 0.8rem;
  margin: 0.25rem 0.25rem 0 0;
}

.more-btn {
  background-color: transparent;
  border: none;
  padding: 0; margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-data-message {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 25px;
}
</style>
