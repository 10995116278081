<template>
  <div class="image-upload-container">
    <label class="image-label">
      <span class="image-label-title">Imagens</span>
      <span class="recommended-size">
        <strong>Tamanho recomendado:</strong> 1365x758 pixels
      </span>
    </label>

    <div>
      <div class="edition-alert" v-if="this.$route.name == 'Editar campanha'">
        <span>
          <strong>Atenção:</strong>
          nos formulários de edição de campanha, as imagens selecionadas são enviadas automaticamente ao serem
          escolhidas, sem a necessidade de confirmar a ação clicando no botão "Editar Campanha" ao final do formulário.
        </span>
      </div>

      <input
        type="file" 
        accept="image/*" 
        multiple 
        ref="fileInput" 
        class="file-input" 
        @change="handleFileChange" 
        style="display: none"
      />
      <div class="image-grid">
        <div v-for="(image, index) in images" :key="index" class="image-preview-container">
          <img :src="image.src" class="image-preview" />
          <button class="delete-button" @click.prevent="handleRemoveBtn(index)">
            &#10006;
          </button>
        </div>

        <div v-if="images.length < 5" class="upload-placeholder" @click.prevent="triggerFileInput">
          <i class="material-icons">folder</i>
        </div>
      </div>
    </div>

    <p v-if="images.length >= 5" class="image-limit-warning">Você atingiu o limite de 5 imagens.</p>
  </div>

  <RemoveDialog
    :is-open="removeDialog"
    @close="this.removeDialog = false"
    :title="'Remover imagem'"
    :removeText="'Esta ação é irreversível e ocorrerá no momento em que confirmar a remoção. Tem certeza que deseja continuar com a deleção desta imagem da campanha?'"
    @confirm="handleConfirmDelete"
  />
</template>

<script>
import { RouteName } from "@/store/routeName";
import RemoveDialog from "./RemoveDialog.vue";
import RafflesHttp from "@/http/RafflesHttp";

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    raffleId: Number,
  },
  components: {
    RemoveDialog,
  },
  data() {
    return {
      images: this.modelValue.map(src => ({ src })),
      removeDialog: false,
      imageToRemove: null,
    };
  },
  watch: {
    modelValue: {
      handler(newImages) {
        if (JSON.stringify(newImages) !== JSON.stringify(this.images.map(image => image.src))) {
          this.images = newImages.map(src => ({ src }));
        }
      },
      immediate: true,
    },
    images: {
      handler(newImages) {
        const base64Files = newImages.map(image => image.src);
        if (JSON.stringify(base64Files) !== JSON.stringify(this.modelValue)) {
          this.$emit('update:modelValue', base64Files);
        }
      },
      deep: true,
    },
  },
  methods: {
    triggerFileInput() {
      if (this.images.length < 5) {
        this.$refs.fileInput.click();
      }
    },
    async handleFileChange(event) {
      const files = Array.from(event.target.files);
      const maxFiles = 5 - this.images.length;

      const filesToProcess = files.slice(0, maxFiles);

      filesToProcess.forEach((file) => {
        this.previewImage(file);
      });

      if (this.$route.name === RouteName.EditRaffle) {
        try {
          this.$emit("loading", true);

          const base64Files = await Promise.all(filesToProcess.map(file => this.convertToBase64(file)));

          await Promise.all(base64Files.map(base64 => 
            RafflesHttp.postNewRaffleImage(this.raffleId, {
              images: [base64],
            })
          ));
          
          this.$emit('updateRaffle');
        } catch (error) {
          console.error(error);
        } finally {
          this.$emit("loading", false);
        }
      }

      this.$refs.fileInput.value = "";
    },
    previewImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.images.push({ src: e.target.result });
      };
      reader.readAsDataURL(file);
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    handleRemoveBtn(index) {
      if(this.$route.name == RouteName.EditRaffle) {
        this.imageToRemove = {
          src: this.images[index].src,
        };
        this.removeDialog = true;
      } else {
        this.removeImage(index);
      }
    },
    async handleConfirmDelete() {
      try {
        this.$emit("loading", true);
        await RafflesHttp.deleteRaffleImages(this.raffleId, this.imageToRemove);
        this.$emit('updateRaffle');
        this.$toast.add({ severity: 'success', summary: 'Sucesso!', detail: 'A imagem foi removida.', life: 3000 });
      } catch (error) {
        this.$emit("loading", false);
        this.$toast.add({ severity: 'error', summary: 'Ops...', detail: 'Ocorreu um erro ao excluir a imagem. Tente novamente!', life: 3000 });
        console.error(error);
      }
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.onerror = (e) => reject(e);
        reader.readAsDataURL(file);
      });
    }
  },
};
</script>

<style scoped>
.image-upload-container {
  margin-bottom: 16px;
}

.image-label {
  display: block;
  margin: 0 0px 10px 0;
}

.image-label-title {
  font-weight: bold;
}

.recommended-size {
  background-color: #dcfce7;
  color: #166534;
  padding: 4px 8px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 14px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.image-preview-container {
  position: relative;
  width: 240px;
  height: 140px;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 2px solid #b3c2f2;
  border-radius: 4px;
}

.upload-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 140px;
  border: 2px dashed #b3c2f2;
  cursor: pointer;
  border-radius: 4px;
}

.delete-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.image-limit-warning {
  color: red;
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px;
}

.edition-alert {
  width: 100%;
  padding: 8px 14px;
  background-color: #dbdbdb;
  color: #0F172A;
  display: flex;
  margin-bottom: 14px;
  border-radius: 8px;
  font-size: 14px;
}
</style>
