<template>
  <div class="container-fluid m-0 p-0">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">Ranking</h6>
            </div>
          </div>
          
          <div class="card-body p-4">
            <DefaultSelect
              label="Selecione uma campanha"
              :options="raffles"
              itemText="name"
              itemValue="id"
              @change="handleCampaign"
              placeholder="Selecione..."
            />

            <div class="table-responsive p-0 ranking-table mt-4">
              <template v-if="ranking.length > 0">
                <table class="table" id="data-table">
                  <tbody>
                    <tr v-for="(r, index) in ranking" :key="r.id" class="table-row">
                      <td style="color: #000000">
                        <div class="d-flex px-2 py-1">
                          {{ index + 1 }}° - {{ r.name }}
                        </div>
                      </td>
                      <td>
                        {{ r.phone.toString().replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "+55 ($1) $2$3-$4") }}
                      </td>
                      <td class="align-middle">
                        <span class="badge-money">
                          <svg xmlns="http://www.w3.org/2000/svg" class="size-4" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                          </svg>
                          {{ r.total_value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }}
                        </span>
                      </td>
                      <td>
                        <p class="badge-blue">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="size-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"></path>
                          </svg> 
                          {{ r.total_quotas }} cotas 
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-else>
                <div class="no-data-message">
                  <p>Selecione uma campanha para o ranking ser exibido.</p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'
import Loading from '@/components/Loading.vue'
import DefaultSelect from '@/components/DefaultSelect.vue'

const api = axios.create({
  baseURL: config.apiUrl
})
export default {
  name: "ranking",
  components: {
    Loading,
    DefaultSelect
  },
  data() {
    return {
      raffles: [],
      ranking: [],
      pages: 0,
      loading: true
    };
  },
  async mounted() {
    await this.init()
  },
  watch: {
    "$store.state.isDarkMode": function () {
      if(this.$store.state.isDarkMode){
        document.querySelectorAll('.input-default').forEach((el) => {
          el.classList.add('dark-mode-input')
        })
      }else{
        document.querySelectorAll('.input-default').forEach((el) => {
          el.classList.remove('dark-mode-input')
        })
      }
    },
  },
  methods: {
    async init(){ 
      this.loading = true
      await this.getRaffles()
      this.loading = false
    },
    async getRaffles() {
      try {
        const response = await api.get("/campaigns/minimum", await this.$store.state.config);
        this.raffles = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getRanking(idCampaign) {
      try {
        const response = await api.get(`/participants/ranking/${idCampaign}/1`, await this.$store.state.config);
        this.ranking = response.data.data;
        this.pages = response.data.pages;
      } catch (error) {
        console.error(error);
      }
    },
    async handleCampaign(e){
      this.ranking = []
      this.pages = 0
      this.loading = true
      await this.getRanking(e.target.value)
      this.loading = false
    }
  },
};
</script>

<style scoped>
  .input-default{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .dark-mode-input{
    background-color: #1a2035 !important;
    border: 1px solid #10131f !important;
    color: #fff;
  }
  .badge-money{
    display: inline-flex;
    align-items: center;
    border-radius: .375rem;
    --tw-bg-opacity: 1;
    background-color: rgb(240 253 244 / var(--tw-bg-opacity));
    padding: .25rem .5rem;
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(21 128 61 / var(--tw-text-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-inset: inset;
    --tw-ring-color: rgb(22 163 74 / .2);
    border: 1pt solid #16a34a;
  }
  .badge-blue{
    display: flex;
    max-width: -moz-max-content;
    max-width: max-content;
    align-items: center;
    gap: .5rem;
    border-radius: 9999px;
    padding: .125rem .75rem;
    font-size: .75rem;
    line-height: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(29 78 216 / var(--tw-text-opacity));
    margin: 0 !important;
  }
  .size-4{
    width: 1rem;
    height: 1rem;
  }

  .table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding-inline: 16px;
    border-radius: 12px;
    margin-bottom: 10px;
  }

  .no-data-message {
    text-align: center;
    font-size: 1.2rem;
  }
</style>