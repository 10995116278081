<template>
  <button
    :type="type"
    class="btn default-button"
    :disabled="disabled || loading"
    :style="buttonStyles"
    @click="handleClick"
  >
    <span v-if="!loading" class="button-label text-xs text-uppercase font-weight-bolder" :style="textStyles">
      {{ label }}
    </span>
    <div v-else class="spinner" :style="spinnerStyles"></div>
  </button>
</template>

<script>
export default {
  name: 'DefaultButton',
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    buttonHeight: {
      type: String,
      default: '40px',
    },
    buttonColor: {
      type: String,
      default: '#e0e0e0',
    },
    textColor: {
      type: String,
      default: '#ffffff',
    },
    spinnerBgColor: {
      type: String,
      default: '#d9b3ff',
    },
    spinnerColor: {
      type: String,
      default: '#661cb0',
    },
  },
  computed: {
    buttonStyles() {
      return {
        backgroundColor: this.buttonColor,
        borderColor: this.buttonColor,
        transition: 'background-color 0.2s, color 0.2s',
        height: this.buttonHeight
      };
    },
    textStyles() {
      return {
        color: this.textColor,
        transition: 'color 0.2s',
      };
    },
    spinnerStyles() {
      return {
        border: `3px solid ${this.spinnerBgColor}`,
        'border-top': `3px solid ${this.spinnerColor}`
      };
    },
  },
  methods: {
    handleClick() {
      this.$emit('confirm');
    },
  },
};
</script>

<style scoped lang="scss">
.default-button {
  position: relative;
  padding: 10px 15px;
  border: 1px solid;
  background-color: transparent;
  color: inherit;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  margin-bottom: 0;
  width: 100%;
  transition: color 0.2s;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    color: #fff;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
  }
}

.button-label {
  display: inline-block;
}

.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
