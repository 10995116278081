<template>
  <dialog :open="isOpen" class="default-dialog">
    <div class="dialog-content">
      <header class="dialog-header">
        <div>
          <h2 class="dialog-title">{{ title }}</h2>
        </div>
        <button @click="closeModal" class="close-icon-button">✖</button>
      </header>

      <div class="no-quotes">
        <i class="material-icons">warning</i>
        <span>
          {{ removeText }}
        </span>
      </div>

      <div class="button-field">
        <DefaultButton
          :label="'Remover'"
          :buttonColor="'#dd0000'"
          :textColor="'#ffffff'"
          @confirm="confirmRemove"
        />

        <DefaultButton
          :label="'Cancelar'"
          :buttonColor="'#e0e0e0'"
          :textColor="'#000000'"
          @confirm="closeModal"
        />
      </div>
    </div>
  </dialog>
</template>

<script>
import { vMaska } from "maska/vue";
import DefaultButton from '@/components/DefaultButton.vue'

export default {
  directives: {
    maska: vMaska,
  },
  props: {
    isOpen: Boolean,
    title: {
      type: String,
      default: 'Remover'
    },
    removeText: {
      type: String,
      default: 'Tem certeza que deseja remover o item?'
    },
  },
  components: {
    DefaultButton,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    confirmRemove() {
      this.$emit('confirm');
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.default-dialog {
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background: none;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
}

dialog {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

dialog[open] {
  opacity: 1;
  pointer-events: inherit;
}

dialog::backdrop {
  backdrop-filter: blur(2px);
  animation: fadein 0.2s linear;
}

.dialog-content {
  width: 450px;
  max-width: 100%;
  background: white;
  border-radius: 8px;
  padding: 25px;
  z-index: 2;
  transform: scale(0.96);
  transition: transform 0.2s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

dialog[open] .dialog-content {
  transform: scale(1);
}

.dialog-title {
  font-family: sans-serif;
  font-size: 26px;
  color: #111111;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  justify-self: center;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(144, 149, 160, 0.5);
  color: #9095a0;
  z-index: 9999999;
  backdrop-filter: blur(4px);
  width: 100vw;
  height: 100vh;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dialog-body {
  margin: 20px 0 0 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.button-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #0f172a;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  margin-bottom: 0;

  &:hover {
    background-color: #d6d6d6;
    color: #0f172a;
  }
}

.close-icon-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.submit-button {
  background-color: #dd0000;
  color: white;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  position: relative;
  transition: background-color 0.2s ease;
  cursor: pointer;
  margin-bottom: 0;

  &:hover {
    background-color: rgb(150, 0, 0);
    color: white;
  }
}

.quote-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quote-form input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.awarded-quotes-list {
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
}

.awarded-quotes-item {
  border: 1px solid #d2d6da !important;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 4px;
  color: #36454F;
}

.quote-number {
  display: flex;
  gap: 5px;
  align-items: center;
}

.no-quotes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 40px 0 40px 0;
  color: #3c3d42;

  i {
    font-size: 34px;
  }

  span {
    text-align: center;
    margin-top: 6px;
  }
}

.remove-quote-btn {
  border: none;
  background-color: transparent;
  padding: 0; margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
