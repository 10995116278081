<template>
  <dialog :open="isOpen" class="default-dialog">
    <div class="dialog-content">
      <div v-if="isLoading || isLoadingWinners">
        <div class="loading-spinner">
          <div class="spinner"></div>
        </div>
      </div>

      <div v-else>
        <header class="dialog-header">
          <div>
            <h2 class="dialog-title">
              {{ isDetails ? "Visualizar ganhadores" : title }}
            </h2>
          </div>
          <button @click="closeModal" class="close-icon-button">✖</button>
        </header>

        <main class="dialog-body">
          <div class="attention-alert" v-if="!isDetails">
            <span>
              Atenção:
              <br />
              Ao informar os ganhadores da campanha, ela será automaticamente
              marcada como concluída. Após a conclusão, não será possível
              realizar novas ações ou alterar qualquer informação relacionada à
              campanha.
              <strong>
                Certifique-se de que todas as alterações necessárias foram
                realizadas antes de prosseguir.
              </strong>
            </span>
          </div>

          <div
            class="winners-form"
            v-for="(prize, index) in gotPrizes"
            :key="prize.id"
          >
            <section
              class="winners-form-fields"
              style="width: 100%; display: flex"
            >
              <DefaultInput
                v-model.number="prize.quota"
                :label="`${index + 1}º Prêmio: (${prize.award})`"
                placeholder="Insira o número da cota..."
                :disabled="isDetails"
                @input="getParticipantFromRaffle(prize, index)"
              />
            </section>

            <section
              class="participant-info"
              v-if="this.gotPrizes[index].participantDetails"
            >
              <div class="info-row">
                <strong>ID da compra:</strong>
                <span>#{{ this.gotPrizes[index].participantDetails.id }}</span>
              </div>

              <div class="info-row">
                <strong>Participante:</strong>
                <span>{{ this.gotPrizes[index].participantDetails.name }}</span>
              </div>

              <div class="info-row">
                <span
                  ><strong>Telefone</strong> (clique para abrir no
                  WhatsApp):</span
                >
                <a
                  :href="
                    getWhatsAppUrl(gotPrizes[index].participantDetails.phone)
                  "
                  target="_blank"
                >
                  {{ formatTelephone(gotPrizes[index].participantDetails.phone) }}
                </a>
              </div>

              <div class="info-row">
                <strong>Data da compra:</strong>
                <span>{{
                  formattedDate(
                    this.gotPrizes[
                      index
                    ].participantDetails.created_at.toString()
                  )
                }}</span>
              </div>

              <div class="info-row">
                <strong>Status:</strong>
                <span>{{
                  getPaymentStatusText(
                    this.gotPrizes[index].participantDetails.payment_status
                  )
                }}</span>
              </div>
              
              <div class="info-row">
                <strong>Valor pago:</strong>
                <span>{{
                  this.gotPrizes[index].participantDetails.value.toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                  )
                }}</span>
              </div>
            </section>

            <section
              class="participant-info"
              v-if="this.gotPrizes[index].participantDetails == false"
            >
              <span
                >Não foram encontrados dados de compradores com a cota
                informada.</span
              >
            </section>
          </div>

          <div class="button-field">
            <DefaultButton
              v-if="!isDetails"
              label="Finalizar"
              :buttonColor="'#661cb0'"
              :textColor="'#ffffff'"
              :spinnerBgColor="'#ffffff'"
              :spinnerColor="'#37016e'"
              :loading="isEndingCampaign"
              @confirm="endCampaign"
            />

            <DefaultButton
              :label="isDetails ? 'Fechar' : 'Cancelar'"
              :buttonColor="'#e0e0e0'"
              :textColor="'#000'"
              @confirm="closeModal"
            />
          </div>
        </main>
      </div>
    </div>
  </dialog>
</template>

<script>
import DefaultInput from "@/components/DefaultInput.vue";
import DefaultButton from "@/components/DefaultButton.vue";
import WinnersHttp from "@/http/WinnersHttp";
import { formatDateTime } from "@/helpers/dateTime.js";
import { getTelephone } from "@/helpers/phoneNumber.js";

export default {
  components: {
    DefaultInput,
    DefaultButton,
  },
  props: {
    isOpen: Boolean,
    isLoading: Boolean,
    prizes: Array,
    campaignId: Number,
    isDetails: Boolean,
  },
  data() {
    return {
      title: "Informar ganhadores",
      cota: "",
      participants: [],
      gotPrizes: [],
      isEndingCampaign: false,
      isLoadingWinners: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getParticipantFromRaffle(prize, index) {
      try {
        const response = await WinnersHttp.getParticipant(
          this.campaignId,
          prize.quota
        );
        this.gotPrizes[index].participantDetails = response.data.data;
      } catch (error) {
        console.error(error);
        this.gotPrizes[index].participantDetails = false;
      }
    },
    getPaymentStatusText(status) {
      switch (status) {
        case "APPROVED":
          return "Pago";
        case "WAITING":
          return "Pendente";
        case "CANCELED":
          return "Cancelado";
        default:
          return "Expirado";
      }
    },
    async endCampaign() {
      try {
        const prizesToUpdate = this.gotPrizes.map((item) => ({
          id: item.id,
          quota: item.quota,
        }));

        this.isEndingCampaign = true;
        await WinnersHttp.setCampaignWinners(this.campaignId, prizesToUpdate);
        this.closeModal();
        this.$emit("endCampaign");
      } catch (error) {
        console.error(error);
      } finally {
        this.isEndingCampaign = true;
      }
    },
    getWhatsAppUrl(telefone) {
      const baseURL = "https://wa.me/";
      return `${baseURL}${telefone}`;
    },
  },
  watch: {
    prizes: {
      async handler() {
        this.gotPrizes = this.prizes;

        if (this.isDetails) {
          try {
            this.isLoadingWinners = true;
            const promises = this.gotPrizes.map((item, index) =>
              this.getParticipantFromRaffle(item, index)
            );
            await Promise.all(promises);
          } catch (error) {
            console.error(error);
          } finally {
            this.isLoadingWinners = false;
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    isFieldsFilled() {
      return this.gotPrizes.every((prize) => prize.quota);
    },
    formattedDate() {
      return (date) => (date ? formatDateTime(date) : "Data indisponível");
    },
    formatTelephone() {
      return (phone) => (phone ? getTelephone(phone) : "Telefone indisponível")
    }
  },
};
</script>

<style scoped lang="scss">
.default-dialog {
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background: none;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
}

dialog {
  pointer-events: none;
  opacity: 0;
  transition:
    opacity 0.2s,
    transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

dialog[open] {
  opacity: 1;
  pointer-events: inherit;
}

dialog::backdrop {
  backdrop-filter: blur(2px);
  animation: fadein 0.2s linear;
}

.dialog-content {
  width: 740px;
  max-width: 100%;
  background: white;
  border-radius: 8px;
  padding: 20px;
  z-index: 2;
  transform: scale(0.96);
  transition: transform 0.2s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

dialog[open] .dialog-content {
  transform: scale(1);
}

.dialog-title {
  font-family: sans-serif;
  font-size: 26px;
  color: #111111;
  margin: 0;
}

.modal-overlay {
  position: fixed;
  display: flex;
  top: 0;
  align-items: center;
  justify-self: center;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(144, 149, 160, 0.5);
  color: #9095a0;
  z-index: 9999999;
  backdrop-filter: blur(4px);
  width: 100vw;
  height: 100vh;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dialog-body {
  margin: 20px 0 0 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.winners-form {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.winners-form-fields {
  display: flex;
  align-items: end;
}

.button-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #0f172a;
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: 0;
  margin-bottom: 0;

  &:hover {
    background-color: #d6d6d6;
    color: #0f172a;
  }
}

.close-icon-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.participant-info {
  width: 100%;
  background-color: #f0f0f0;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-row strong {
  font-weight: bold;
  color: #333;
}

.info-row span {
  font-weight: normal;
  color: #555;
}

.contact-row {
  padding: 8px 0 0 0;
}

.attention-alert {
  background-color: #661cb0;
  border-radius: 14px;
  padding: 14px;
  margin-bottom: 26px;

  span {
    color: white;
    font-size: 15px;
  }
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
}

.spinner {
  margin-top: 10px;
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-top: 5px solid #6a1b9a;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
