import { createRouter, createWebHistory } from "vue-router";
import Raffles from "../views/Raffles/RafflesList/Raffles.vue";
import NewRaffle from "../views/Raffles/RafflesCreation/NewRaffle.vue";
import RafflePayment from "../views/Raffles/RafflesCreation/RafflePayment.vue";
import Settings from "../views/Settings/Settings.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import Ranking from "../views/Ranking/Ranking.vue";
import Orders from "../views/Orders/Orders.vue";
import { RouteName } from "../store/routeName";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/raffles",
  },
  {
    path: "/raffles",
    name: "Campanhas",
    component: Raffles,
  },
  {
    path: "/raffles/new-raffle",
    name: RouteName.NewRaffle,
    component: NewRaffle,
  },
  {
    path: "/raffles/:slug/edit",
    name: RouteName.EditRaffle,
    component: NewRaffle,
  },
  {
    path: "/raffles/pay/:slug",
    name: 'Pagamento de rifa',
    component: RafflePayment,
  },
  {
    path: "/ranking",
    name: "Ranking",
    component: Ranking,
  },
  {
    path: "/orders",
    name: "Histórico",
    component: Orders,
  },
  {
    path: "/Settings",
    name: "Configurações",
    component: Settings,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
