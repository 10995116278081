<template>
  <div
    class="page-header align-items-start min-vh-100"
    style="background-color: transparent;"
  >
    <span class="mask opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-body">
              <img src="@/assets/img/logo.png" style="width: 40%; display: block; margin: 0 auto">

              <form role="form" class="text-start mt-3">
                <DefaultInput
                  class="mb-2"
                  v-model="name"
                  label="Nome"
                  placeholder="Insira seu nome"
                />

                <DefaultInput
                  class="mb-2"
                  v-model="lastname"
                  label="Sobrenome"
                  placeholder="Insira seu sobrenome"
                />

                <DefaultInput
                  class="mb-2"
                  v-model="email"
                  label="E-mail"
                  type="email"
                  placeholder="Insira seu e-mail"
                />

                <DefaultInput
                  class="mb-2"
                  v-model="password"
                  label="Senha"
                  type="password"
                  placeholder="Insira sua senha"
                />

                <div class="input-group input-group-outline m-0 d-flex flex-column">
                  <label class="input-label">
                    <span class="text-xs text-uppercase text-body font-weight-bolder"
                      >WhatsApp</span
                    >
                    <input
                      v-model="phone"
                      type="text"
                      class="form-control default-bordered-input"
                      v-maska="'(##) #####-####'"
                      placeholder="(xx) xxxxx-xxxx"
                    />
                  </label>
                </div>
          
                <div class="text-center">
                  <button class="btn btn-icon btn-3 campaign-btn m-0" type="button" @click.prevent="register">
                    <span class="btn-inner--text btn-text">Cadastrar</span>
                  </button>
                </div>
                <p class="mt-4 text-sm text-center">
                  Já tem conta?
                  <router-link
                    :to="{ name: 'SignIn' }"
                    class="text-success text-gradient font-weight-bold"
                    >Fazer login</router-link
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from '@/components/DefaultInput.vue'
import { vMaska } from "maska/vue";
import { mapMutations } from "vuex";
import axios from 'axios'
import config from '@/store/config'

function mphone(v) {
  var r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}

const api = axios.create({
  baseURL: config.apiUrl,
})

const VerifyApi = axios.create({
  baseURL: 'http://nswebservice.com.br:13090/chat/whatsappNumbers/beeasy',
  headers: {
    apikey: 278662
  }
})

export default {
  name: "sign-up",
  components: {
    DefaultInput
  },
  directives: {
    maska: vMaska
  },
  data() {
    return {
      name: "",
      lastname: "",
      email: "",
      password: "",
      phone: "",
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async handleInputValue(event) {
      const { name, value } = event.target;
      this[name] = value;
    },

    async handleInputValuePhone(event) {
      var { name, value } = event.target;
      this[name] = mphone(value);
      event.target.value = this[name];
    },
    formatPhoneForApi(phone) {
      const cleanedPhone = phone.replace(/\D/g, "");
      
      return `55${cleanedPhone}`;
    },
    async register(){
      try {
        const formattedPhone = this.formatPhoneForApi(this.phone);
        
        const response = await VerifyApi.post('', {
          numbers: [formattedPhone],
        });

        if (response && response.data.at(0).exists === false) {
          this.$toast.add({ severity: 'error', summary: 'Erro ao consultar número', detail: 'Certifique-se que o telefone inserido é um whatsapp válido.', life: 3000 });
          return;
        }
      } catch (error) {
        console.error(error);
      }

      try {
        const response = await api.post("/register", {
          full_name: `${this.name} ${this.lastname}`,
          email: this.email,
          password: this.password,
          phone: this.phone,
        });
        if(response.data.id){
          this.$toast.add({
            severity: 'success',
            summary: 'Eba!',
            detail: 'Sua conta foi criada com sucesso! 🚀',
            life: 3000
          });
          this.$router.push('/login')
        }
      } catch (error) {
        if(error.response.data.error.indexOf('Duplicate entry') > -1){
          this.$toast.add({
            severity: 'warn',
            summary: 'Atenção',
            detail: 'Este e-mail parece já estar vinculado a uma conta no Rifa365. Caso necessário, entre em contato com nosso suporte.',
            life: 3000
          });
          return;
        }
        console.error(error);
      }
    }
  },
};
</script>

<style scoped lang="scss">
  .campaign-btn {
    color: #ffffff;
    background-color: #e41b80;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    line-height: 0;
    height: 40px;
    width: 100%;
    margin-top: 15px !important;

    .btn-icon {
      font-size: 1.6em;
    }

    &:hover {
      color: #ffffff;
    }
  }

  .more-btn {
    background-color: transparent;
    border: none;
    padding: 0; margin: 0;
  }

  .no-campaign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    padding: 50px 0;
  }
</style>
